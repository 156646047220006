/* global angular */

'use strict';

angular.module('managerApp').config( ($routeProvider) => {
    $routeProvider.when('/settings/ip', {
        templateUrl: 'app/settings/ip/ip.html',
        controller: 'IPController',
        authenticate: 'user',
        hasPermissions: ['SETTINGS:GET', 'SETTINGS:POST', 'SETTINGS:DELETE']
    });
});
